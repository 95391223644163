<template>
  <div class="empty-container" v-if="state.videoList.length == 0">
    <div>
      <v-iconFontSymbol :icon="'iconfavorites'" style="font-size: 1.8rem" />
      <div class="text-containers">快去收藏喜欢的课程吧</div>
    </div>
  </div>

  <div v-if="state.videoList.length > 0">
    <div class="history-container">
      <VideoHistoryTitle
        title="全部视频"
        :isEdit="state.isEdit"
        :editClick="editClick"
        :finshClick="finshClick"
      />
      <div class="history-list-container">
        <div class="list-detail" style="padding-top: 20px">
          <div :class="state.style ? 'content-list-other' : 'content-list'">
            <VideoHistoryComponent
              :process="false"
              :data="state.videoList"
              :isEdit="state.isEdit"
              :toDetail="toDetail"
              :changeClick="changeClick"
            />
          </div>
        </div>
      </div>
    </div>
    <VideoHistoryDel
      :isEdit="state.isEdit"
      :allClick="allClick"
      :deleteClick="deleteClick"
      :isAllChecked="state.isAllChecked"
    />
  </div>
</template>
<script>
import { reactive, getCurrentInstance, onMounted } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";

import personalApi from "@/api/personal";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import VideoHistoryDel from "@/components/others/VideoHistoryDel";
import VideoHistoryTitle from "@/components/titles/VideoHistoryTitle";
import VideoHistoryComponent from "@/components/videos/VideoHistoryComponent";

export default {
  name: "MyCollection",
  components: {
    VideoHistoryDel,
    VideoHistoryTitle,
    VideoHistoryComponent,
    "v-iconFontSymbol": IconFontSymbol,
  },
  setup() {
    const state = reactive({
      videoList: [],
      isEdit: false,
      isAllChecked: false,
      style: true,
    });
    const { proxy } = getCurrentInstance();
    const router = useRouter();

    const getListData = async () => {
      const res = await proxy.$request.handleFetchGet(personalApi.col_list);
      let collect_list = res.collect_list || [];
      collect_list.forEach((item) => {
        item.active = false;
      });
      state.videoList = collect_list;
      Toast.clear();
    };

    const editClick = () => {
      state.isEdit = true;
      state.style = false;
    };

    const finshClick = () => {
      state.isEdit = false;
      state.style = true;
    };

    const changeClick = () => {
      let isCheckedArr = [];
      state.videoList.forEach((data) => {
        isCheckedArr.push(data.active);
      });
      if (isCheckedArr.indexOf(false) == -1) {
        state.isAllChecked = true;
      } else {
        state.isAllChecked = false;
      }
    };

    const allClick = (value) => {
      state.videoList.forEach((data) => {
        data.active = value;
      });
    };

    const toDetail = (video_id) => {
      router.push({
        path: "/hotDetail",
        name: "hotDetail",
        query: {
          isHot: true,
          video_id: video_id,
        },
      });
    };

    const deleteData = async (id_list) => {
      await proxy.$request.handleFetchPostJson(personalApi.del_col, {
        id_list: id_list,
      });
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });
      getListData();
    };

    const deleteClick = () => {
      let id_list = [];
      state.videoList.forEach((item) => {
        if (item.active) {
          id_list.push(item.id);
        }
      });
      deleteData(id_list);
    };

    onMounted(() => {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });
      getListData();
    });

    return {
      state,
      changeClick,
      editClick,
      finshClick,
      allClick,
      toDetail,
      deleteClick,
    };
  },
};
</script>
